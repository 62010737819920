import { createSlice, PayloadAction } from "@reduxjs/toolkit";
export type ILoginData = typeof initialState.loginData;
const initialState = {
  isAuth: false,
  isConnected: false,
  isFree: false,
  loginData: {
    _id: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    isSuperAdmin: false,
    password: "",
    token: "",
    currentBranch: {
      _id: "",
      name: "",
    },
    role: [
      {
        _id: "",
        employeeId: "",
        roleId: "",
        branchId: "",
        role: {
          _id: "",
          // isDeleted: false,
          name: "",
          order: false,
          statistics: false,
          company: false,
          customer: false,
          contract: false,
          transaction: false,
          queue: false,
          employee: false,
          purchase: false,
          income: false,
          car: false,
          warehouse: false,
          cashbox: false,
          supplier: false,
          branch: false,
          role: false,
          bank: false,
          reason: false,
          currency: false,
          carBrand: false,
          carModel: false,
          carColor: false,
          transactionType: false,
          paymentMethod: false,
          managerBot: false,
          dispatcher: false,
          vat: false,
          orderCreate: false,
          orderUpdate: false,
          orderDelete: false,
          customerCreate: false,
          customerUpdate: false,
          customerDelete: false,
          contractCreate: false,
          contractUpdate: false,
          contractDelete: false,
          transactionCreate: false,
          transactionUpdate: false,
          transactionDelete: false,
          queueCreate: false,
          queueUpdate: false,
          queueDelete: false,
          employeeCreate: false,
          employeeUpdate: false,
          employeeDelete: false,
          purchaseCreate: false,
          purchaseUpdate: false,
          purchaseDelete: false,
          incomeCreate: false,
          incomeUpdate: false,
          incomeDelete: false,
          carCreate: false,
          carUpdate: false,
          carDelete: false,
          warehouseCreate: false,
          warehouseUpdate: false,
          warehouseDelete: false,
          cashboxCreate: false,
          cashboxUpdate: false,
          cashboxDelete: false,
          carStock: false,
          carStockCreate: false,
          carStockUpdate: false,
          carStockDelete: false,
          carStatistics: false,
          managerStatistics: false,
          branchCreate: false,
          branchUpdate: false,
          branchDelete: false,
          companyCreate: false,
          companyUpdate: false,
          companyDelete: false,
          roleCreate: false,
          roleUpdate: false,
          roleDelete: false,
          bankCreate: false,
          bankUpdate: false,
          bankDelete: false,
          reasonCreate: false,
          reasonUpdate: false,
          reasonDelete: false,
          currencyCreate: false,
          currencyUpdate: false,
          currencyDelete: false,
          carBrandCreate: false,
          carBrandUpdate: false,
          carBrandDelete: false,
          carModelCreate: false,
          carModelUpdate: false,
          carModelDelete: false,
          carColorCreate: false,
          carColorUpdate: false,
          carColorDelete: false,
          internalTransfer: false,
          internalTransferCreate: false,
          internalTransferUpdate: false,
          internalTransferDelete: false,
          organizationalTransfer: false,
          organizationalTransferCreate: false,
          organizationalTransferUpdate: false,
          organizationalTransferDelete: false,
          transferReason: false,
          markStateUpdate: false,
          paymentConfirm: false,
          revision: false,
          revisionCreate: false,
          revisionUpdate: false,
          revisionDelete: false,
          dashboard: false,
          branchContractStatistics: false,
          managerContractStatistics: false,
          carContractStatistics: false,
          longStandingCars: false,
          branchCarStatistics: false,
          warehouseCarStatistics: false,
          carState: false,
          serviceRoles: [],
          blockMark: false,
          installment: false,
          installmentCreate: false,
          installmentUpdate: false,
          installmentDelete: false,
        },
        branch: {
          _id: "",
          name: "",
        },
      },
    ],
  },
};

const LoginSlice = createSlice({
  name: "login-data",
  initialState,
  reducers: {
    setLoginData(state, payload: PayloadAction<ILoginData>) {
      state.loginData = payload.payload;
      state.isAuth = true;
    },
    setSocketConnect(state, payload: PayloadAction<boolean>) {
      state.isConnected = payload.payload;
    },
    setIsFree(state, payload: PayloadAction<boolean>) {
      state.isFree = payload.payload;
    },
  },
});

export const { setLoginData, setSocketConnect, setIsFree } = LoginSlice.actions;

export default LoginSlice.reducer;
